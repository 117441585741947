<template>
  <section>
    <!-- banner -->
    <b-card v-if="item" no-body class="m-2 overflow-hidden">
      <div :style="bannerStyle">
        <safe-img
          :src="getImageSrc(item.imageUrl)"
          :placeholder="placeholder"
          class="appBanner zindex-0"
          :alt="`Image of ${
            item.name[currentLocale] || Object.values(item.name)[0]
          }`"
        />
        <h1 class="text-center text-white mt-2 mb-1 zindex-1">
          {{ item.name[currentLocale] || Object.values(item.name)[0] }}
        </h1>
        <div v-if="item.headline" class="mx-5 px-25 zindex-1">
          <h3 class="text-center text-white mx-xl-5 px-lg-5 font-weight-normal">
            {{
              item.headline[currentLocale] || Object.values(item.headline)[0]
            }}
          </h3>
        </div>
      </div>
    </b-card>

    <b-card class="backoffice-card m-2">
      <!-- go back -->
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a>
          <h4 class="m-0 font-weight-600">Spaces</h4>
        </div>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner
          type="grow"
          small
          class="mx-auto mt-3 d-block text-primary"
        />
      </div>

      <!-- Container with data -->
      <div v-else-if="item && !isLoading">
        <b-row class="p-2 w-100 m-0">
          <!-- RIGHT: App Details -->
          <b-col cols="12" md="6">
            <h4 class="mb-1">Description</h4>
            <p
              v-if="item.description[currentLocale]"
              v-html="
                item.description[currentLocale] ||
                Object.values(item.description)[0]
              "
            />
            <p v-else>
              No description yet.
            </p>
          </b-col>
          
          <!-- LEFT: App Video and Images -->
          <b-col cols="12" md="6">
            <div>
                <h4 class="mb-1">Watch this App in action!</h4>
                <video
                v-if="item.videoUrl"
                v-bind:src="item.videoUrl"
                />
                <p v-else>
                Sorry, no video found.
                </p>
            </div>
            <div style="margin-top:40px;">
                <h4 class="mb-1">Images gallery</h4>
                <img
                v-if="item.imgUrl"
                v-bind:src="item.imgUrl"
                />
                <p v-else>
                Sorry, no images found.
                </p>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Container without data: placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="placeholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: "items" }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { getImageResource } from "@/@core/utils/image-utils";
import placeholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import SafeImg from "@core/components/safe-img/SafeImg.vue";
import { BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import CustomAddonNameModal from "@core/components/modal/CustomAddonNameModal.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { randomString } from "@/@core/utils/utils";

export default {
  name: "AddonDetails",
  components: {
    SafeImg,
    BFormCheckbox,
    vSelect,
    CustomAddonNameModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      imageSrc: null,
      check: false,
      loading: false,
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    item() {
      console.log(JSON.parse(JSON.stringify(this.$store.getters.communities?.allCommunities?.unpaginated)));
      return this.$store.getters.item("communities", this.slug);
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    placeholder() {
      return placeholder;
    },
    bannerImage() {
      if (this.item && this.item.imageUrl) {
        return this.getImageSrc(this.item.imageUrl);
      }
      return this.placeholder;
    },
    bannerStyle() {
      return {
        /*       "background-image": `url(${this.bannerImage})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "background-position": "center", */
        "border-radius": "1.2rem",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
        "padding-top": "60px",
        "padding-bottom": "65px",
        height: "200px",
      };
    },
  },
  async created() {
    this.isLoading = true;
    console.log('item: ', this.item);
    if (!this.item || !this.item.slug) {
      await this.fetchData();
    }
    this.isLoading = false;
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'communities',
        customName: 'communities',
        perPage: 30,
        forceAPICall: true,
        storedKey: 'allCommunities',
        requestConfig: {
          communitySlug: this.slug,
        },
      });
    },
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}

.card-img {
  height: 150px !important;
}
.card-blog {
  height: 320px;
  width: 350px !important;
}
.backoffice-card {
  min-height: 65vh;
}
.appBanner {
  position: absolute;
  width: 100%;
}
</style>
